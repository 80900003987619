import React, { Suspense } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import './App.css';
import Spinner from './components/UI/Spinner/Spinner';
import Layout from './hoc/Layout/Layout';

import { routeData } from './data/routes';

function App() {

    const routes = routeData.map((item, index) => (
        <Route key={index} path={item.path} exact component={item.comp} />
    ));

    return (
        <Suspense fallback={<Spinner />}>
            <AnimatePresence exitBeforeEnter>
                <Layout pathname={useLocation().pathname}>
                    <Switch>
                        {routes}
                        <Redirect to="/" />
                    </Switch>
                </Layout>
            </AnimatePresence>
        </Suspense>
    );
}

export default App;
