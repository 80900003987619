import $ from 'jquery';

const SCROLL_TIME = 400;

export const SmoothScroll = (hashLocation) => {
    let hashElement = $(hashLocation).offset();
    if(hashElement) {
        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (400) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
            scrollTop: hashElement.top
        }, SCROLL_TIME, function(){
            // Add hash (#) to URL when done scrolling (default click behavior)
            window.location.hash = hashLocation;
        });
    }
};

export const ScrollToTop = () => {
    // Using jQuery's animate() method to add smooth page scroll
    // The optional number (400) specifies the number of milliseconds it takes to scroll to the specified area
    $('html, body').animate({
        scrollTop: 0
    }, SCROLL_TIME, function(){
        // Remove hash (#) from URL when done scrolling to the top
        window.location.hash = "";
    });
};

export const NavScroll = (navLocation) => {
    const hashLocation = navLocation.split('#')[1];
    if (hashLocation) {
        SmoothScroll('#' + hashLocation);
    } else {
        ScrollToTop();
    }
}

export const InitScroll = () => {
    if(window.location.hash) {
        SmoothScroll(window.location.hash);
    } else {
        ScrollToTop();
    }
};
