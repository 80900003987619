import React from 'react';
import classes from './Footer.module.css';

import { socialLinks } from '../../../data/links';
import Copyright from '../Copyright/Copyright';

const Footer = () => {
    const links = socialLinks.map((item, index) => (
        <li key={index} onClick={() => { window.location = item.location }}>
            <item.icon size={item.size}/>
        </li>
    ));

    return (
        <section className={classes.Footer}>
            <div className={classes.Container}>
                <div className={classes.Text}>
                    <h1>Location</h1>
                    <p> Johannesburg, South Africa </p>
                </div>
                <ul className={classes.Links}>
                    {links}
                </ul>
                <Copyright />
            </div>
        </section>
    )
}

export default Footer
