import React, { Component } from 'react';

import classes from './Layout.module.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import Footer from '../../components/UI/Footer/Footer';
import GotoTop from '../../components/Navigation/GotoTop/GotoTop';
import { InitScroll } from '../../components/Navigation/Scroll/Scroll';
import Animation, { routeAnimation } from '../../components/UI/Animation/Animation';

class Layout extends Component {

    state = {
        pathname: null,
        showSideDrawer: false,
        smallToolbar: false
    }

    componentWillMount() {
        window.addEventListener("scroll", this.onPageScoll);
        InitScroll();
    }

    componentWillUnmount () {
        window.removeEventListener("scroll", this.onPageScoll);
    }

    componentDidMount() {
        this.setState({pathname: this.props.pathname});
    }

    componentDidUpdate() {
        if(this.props.pathname !== this.state.pathname) {
            this.setState({pathname: this.props.pathname});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.pathname === nextState.pathname) {
            if(this.state === nextState) {
                return false;
            }
        }
        return true;
    }

    onPageScoll = () => {
        const smallToolbar = document.documentElement.scrollTop > 50;
        if (smallToolbar !== this.state.smallToolbar) {
            this.setState({smallToolbar: smallToolbar});
        }
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
    }

    render() {

        let mainClasses = [classes.Content, classes.Close];
        let sideDrawer = null;
        if (this.state.showSideDrawer) {
            mainClasses = [classes.Content, classes.Open];
            sideDrawer = (
                <SideDrawer
                    open={this.state.showSideDrawer}
                    closed={this.sideDrawerToggleHandler} />
            );
        }

        return (
            <>
                <div className={mainClasses.join(' ')}>
                    <Toolbar
                        sideDrawerOpen={this.state.showSideDrawer}
                        smallToolbar={this.state.smallToolbar}
                        drawerToggleClicked={this.sideDrawerToggleHandler} />
                    {sideDrawer}
                    <main>
                        <Animation
                            key={this.props.pathname}
                            animation={routeAnimation(
                                this.state.pathname,
                                this.props.pathname
                            )}
                        >
                            {this.props.children}
                        </Animation>
                    </main>
                    <footer>
                        <Footer />
                    </footer>
                </div>
                <GotoTop show={this.state.smallToolbar} />
            </>
        )
    }
}

export default Layout;
