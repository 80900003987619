import React from 'react';
import { motion } from 'framer-motion';

import { fade, slideLeft, slideRight } from './Animations';
import { routeData } from '../../../data/routes';

const Animation = (props) => {
    let animationProps = {
        initial: "out",
        variants: props.animation
    }
    if(props.animation === fade) {
        animationProps = {
            ...animationProps,
            animate: "in"
        }
    }
    if([slideLeft, slideRight].includes(props.animation)) {
        animationProps = {
            ...animationProps,
            animate: "end",
            exit: "out"
        }
    }
    return (
        <motion.div {...animationProps} >
            {props.children}
        </motion.div>
    )
}

const findPathIndex = (path) => (
    routeData.findIndex(item => (
        item.path === path
    ))
);

export const routeAnimation = (from, to) => {
    if(from === null) {
        return fade;
    }
    const fromIndex = findPathIndex(from);
    const toIndex = findPathIndex(to);

    if(fromIndex < toIndex) {
        return slideLeft;
    }
    return slideRight;
}

export default Animation;
