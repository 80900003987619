import React from 'react';

import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const Toolbar = (props) => {

    let headerClasses = [classes.Toolbar];
    if (props.smallToolbar) {
        headerClasses.push(classes.Small);
    } else {
        headerClasses.push(classes.Big);
    }

    return (
        <header className={headerClasses.join(' ')}>
            <div className={classes.Logo}>
                <Logo />
            </div>
            <nav className={classes.DesktopOnly}>
                <NavigationItems />
            </nav>
            <DrawerToggle
                close={props.sideDrawerOpen}
                clicked={props.drawerToggleClicked} />
        </header>
    );
}

export default Toolbar;