import React from 'react';
import classes from './Copyright.module.css';

const Copyright = () => {
    return (
        <span className={classes.Copyright}>
            Ideon van Niekerk &copy; 2021
        </span>
    )
}

export default Copyright
