import React from 'react';
import SiteLogo from '../../assets/images/logo.png';
import classes from './Logo.module.css';

const logo = (props) => (
    <div className={classes.Logo} style={{height: props.height}}>
        <a href="/">
            <img src={SiteLogo} width="85px" height="64px" alt="ideonvn logo" />
        </a>
        <span>Ideon van Niekerk</span>
    </div>
);

export default logo;