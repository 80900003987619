import { lazy } from 'react';

const Home = lazy(() => import('../containers/Home/Home'));
const About = lazy(() => import('../containers/About/About'));
const Skill = lazy(() => import('../containers/Skills/Skills'));
const Experience = lazy(() => import('../containers/Experience/Experience'));
const Contact = lazy(() => import('../containers/Contact/Contact'));

export const routeData = [
    {
        text: "Home",
        path: "/",
        comp: Home
    },
    {
        text: "About",
        path: "/about",
        comp: About
    },
    {
        text: "Skills",
        path: "/skills",
        comp: Skill
    },
    {
        text: "Experience",
        path: "/experience",
        comp: Experience
    },
    {
        text: "Contact",
        path: "/contact",
        comp: Contact
    }
];
