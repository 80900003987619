import { FaFacebookSquare, FaLinkedin, FaBlogger, FaYoutubeSquare, FaInstagramSquare } from 'react-icons/fa';

const iconsSize = "40px";
export const socialLinks = [
    {
        location: "https://www.linkedin.com/in/ideon-van-niekerk/",
        icon: FaLinkedin,
        size: iconsSize
    },
    {
        location: "http://ideonvn.blogspot.com/",
        icon: FaBlogger,
        size: iconsSize
    },
    {
        location: "https://www.instagram.com/vanniekerkideon",
        icon: FaInstagramSquare,
        size: iconsSize
    },
    {
        location: "https://www.facebook.com/ideon.vanniekerk",
        icon: FaFacebookSquare,
        size: iconsSize
    },
    {
        location: "https://www.youtube.com/channel/UCyXe0fSpFvMo17zZh743XHA",
        icon: FaYoutubeSquare,
        size: iconsSize
    },
];
