import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

import { routeData } from '../../../data/routes';

const navigationItems = (props) => {
    const items = routeData.map((item, index) => (
        <NavigationItem key={index} link={item.path} exact>{item.text}</NavigationItem>
    ));
    
    return (
        <ul className={classes.NavigationItems}>
            {items}
        </ul>
    );
};

export default navigationItems;