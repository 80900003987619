export const fade = {
    in: {
        opacity: 1
    },
    out: {
        opacity: 0
    }
}

export const slideLeft = {
    in: {
        opacity: 1,
        x: -300,
    },
    out: {
        opacity: 0,
        x: 300,
    },
    end: {
        x: 0,
        opacity: 1
    }
};

export const slideRight = {
    in: {
        opacity: 1,
        x: 300,
    },
    out: {
        opacity: 0,
        x: -300,
    },
    end: {
        x: 0,
        opacity: 1
    }
};
