import React from 'react'
import { IoIosArrowUp } from 'react-icons/io';

import classes from './GotoTop.module.css';
import { ScrollToTop } from '../Scroll/Scroll';

const GotoTop = (props) => {

    let scrollClasses = [classes.GotoTop, classes.HideGotoTop];
    if (props.show) {
        scrollClasses = [classes.GotoTop, classes.ShowGotoTop];
    }

    return (
        // eslint-disable-next-line
        <a className={scrollClasses.join(' ')} onClick={() => ScrollToTop()}>
            <IoIosArrowUp size='2em' />
        </a>
    )
}

export default GotoTop
